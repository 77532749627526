import { Component, DestroyRef, HostListener, inject, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT, isPlatformBrowser, NgStyle } from '@angular/common';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { NgxValidatorService } from '@angular-magic/ngx-validator';
import { SeoService } from '@core/services/seo.service';
import { BreakpointService } from '@shared/modules/breakpoint';
import { filter, map, tap } from 'rxjs/operators';
import { AppRoutes } from '@core/enums/generic/routes.enum';
import { LanguageUtils } from '@core/utils/language.utils';
import { ConfigService } from '@core/services/config.service';
import { NavigationUtils } from '@core/utils/navigation.utils';
import { BrowserDetectionService } from '@core/services/browser-detection.service';
import { NgxToastModule } from '@angular-magic/ngx-toast';
import { Platform } from '@angular/cdk/platform';
import posthog from 'posthog-js'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  imports: [
    NgxToastModule,
    NgStyle,
    RouterOutlet,
  ],
  standalone: true,
})
export class AppComponent implements OnInit {
  isBrowser: boolean;

  private destroyRef = inject(DestroyRef);

  constructor(
    private translateService: TranslateService,
    private configService: ConfigService,
    private ngxValidatorService: NgxValidatorService,
    private seoService: SeoService,
    private router: Router,
    private breakpointService: BreakpointService,
    private browserDetectionService: BrowserDetectionService,
    private platform: Platform,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (this.browserDetectionService.isOldSafari()) {
      // Redirect to a custom page for old browsers
      void this.router.navigate(['/', AppRoutes.oldPageBrowser]);
    }

    this.seoMetaTags();
    this.setValidationsText();

    if (isPlatformBrowser(this.platformId)) {
      this.breakpointService.pageWidth.next(window.innerWidth);
    }

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url),
        tap(() => {
          if (isPlatformBrowser(this.platformId)) {
            posthog.capture('$pageview');
          }
        }),
        takeUntilDestroyed(this.destroyRef))
      .subscribe((url) => {
        const checkFirstPage = url.split('/')[1];
        let page = url.split('/')[2];
        let language = LanguageUtils.languageSeo(url);

        if (!this.configService.isSupportedLanguage(language)) {
          language = this.translateService.currentLang;
        }

        if (language === this.translateService.currentLang && isPlatformBrowser(this.platformId)) {
          if (this.platform.IOS || this.platform.SAFARI) {
            this.document.documentElement.scrollIntoView();
          } else {
            NavigationUtils.navigateToTop();
          }
        }

        if (checkFirstPage === AppRoutes.notFound) {
          page = 'home';
          language = this.translateService.currentLang;
        }

        let image = 'assets/images/home/home.png';

        switch (page) {
          case AppRoutes.contact:
            image = 'assets/images/contact.jpg';
            break;
        }

        // page for payment seo if buy subscription or buy content
        const paymentType = url.split('/')[3];

        if (paymentType) {
          switch (paymentType) {
            case AppRoutes.createAccount:
              page = 'create-account';
              break;
            case AppRoutes.content:
              page = 'content';
              break;
          }
        }

        if (page) {
          this.seoService.setSeoMetaTags(language, page, image);
        }

        this.seoService.setLangAttributes(url);

        this.seoService.setCanonicalLink(url);
      });
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.breakpointService.pageWidth.next(event.target.innerWidth);
  }

  seoMetaTags(): void {
    if (isPlatformBrowser(this.platformId)) {
      if ('caches' in window) {
        caches.keys()
          .then(function (keyList) {
            return Promise.all(keyList.map(function (key) {
              return caches.delete(key);
            }));
          })
      }

      if (window.navigator && navigator.serviceWorker) {
        navigator.serviceWorker.getRegistrations()
          .then(function (registrations) {
            for (let registration of registrations) {
              registration.unregister();
            }
          });
      }
    }
  }

  private setValidationsText(): void {
    this.translateService.onLangChange.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      const messages = {
        required: this.translateService.instant('validation-messages.required'),
        email: this.translateService.instant('validation-messages.email'),
        sizeValidation: this.translateService.instant('validation-messages.sizeValidation'),
        minLengthFile: this.translateService.instant('validation-messages.minLengthFile'),
        maxLengthFile: this.translateService.instant('validation-messages.maxLengthFile'),
      };

      this.ngxValidatorService.setValidationMessages(messages);
      this.document.documentElement.lang = this.translateService.currentLang;
    });
  }
}
