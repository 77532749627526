import * as i0 from '@angular/core';
import { InjectionToken, ɵglobal as _global, Injectable, Inject, NgModule, makeEnvironmentProviders } from '@angular/core';
import { Store, withNgxsPlugin } from '@ngxs/store';
import { getActionTypeFromInstance, InitState, NGXS_PLUGINS } from '@ngxs/store/plugins';
import { catchError, tap } from 'rxjs/operators';
const NGXS_DEVTOOLS_OPTIONS = new InjectionToken('NGXS_DEVTOOLS_OPTIONS');

/**
 * Adds support for the Redux Devtools extension:
 * http://extension.remotedev.io/
 */
class NgxsReduxDevtoolsPlugin {
  constructor(_options, _injector, _ngZone) {
    this._options = _options;
    this._injector = _injector;
    this._ngZone = _ngZone;
    this.devtoolsExtension = null;
    this.globalDevtools = _global['__REDUX_DEVTOOLS_EXTENSION__'] || _global['devToolsExtension'];
    this.unsubscribe = null;
    this.connect();
  }
  ngOnDestroy() {
    this.unsubscribe?.();
    this.globalDevtools?.disconnect();
  }
  /**
   * Lazy get the store for circular dependency issues
   */
  get store() {
    return this._injector.get(Store);
  }
  /**
   * Middleware handle function
   */
  handle(state, action, next) {
    if (!this.devtoolsExtension || this._options.disabled) {
      return next(state, action);
    }
    return next(state, action).pipe(catchError(error => {
      const newState = this.store.snapshot();
      this.sendToDevTools(state, action, newState);
      throw error;
    }), tap(newState => {
      this.sendToDevTools(state, action, newState);
    }));
  }
  sendToDevTools(state, action, newState) {
    const type = getActionTypeFromInstance(action);
    // if init action, send initial state to dev tools
    const isInitAction = type === InitState.type;
    if (isInitAction) {
      this.devtoolsExtension.init(state);
    } else {
      this.devtoolsExtension.send({
        ...action,
        action: null,
        type
      }, newState);
    }
  }
  /**
   * Handle the action from the dev tools subscription
   */
  dispatched(action) {
    if (action.type === "DISPATCH" /* ReduxDevtoolsActionType.Dispatch */) {
      if (action.payload.type === "JUMP_TO_ACTION" /* ReduxDevtoolsPayloadType.JumpToAction */ || action.payload.type === "JUMP_TO_STATE" /* ReduxDevtoolsPayloadType.JumpToState */) {
        const prevState = JSON.parse(action.state);
        // This makes the DevTools and Router plugins compatible with each other.
        // We check for the existence of the `router` state and ensure it has the
        // `trigger` property, confirming that it is our router state (coming from `@ngxs/router-plugin`).
        // This enables a time-traveling feature, as it not only restores the state but
        // also allows the `RouterState` to navigate back when the action is jumped.
        if (prevState.router && prevState.router.trigger) {
          prevState.router.trigger = 'devtools';
        }
        this.store.reset(prevState);
      } else if (action.payload.type === "TOGGLE_ACTION" /* ReduxDevtoolsPayloadType.ToggleAction */) {
        console.warn('Skip is not supported at this time.');
      } else if (action.payload.type === "IMPORT_STATE" /* ReduxDevtoolsPayloadType.ImportState */) {
        const {
          actionsById,
          computedStates,
          currentStateIndex
        } = action.payload.nextLiftedState;
        this.devtoolsExtension.init(computedStates[0].state);
        Object.keys(actionsById).filter(actionId => actionId !== '0').forEach(actionId => this.devtoolsExtension.send(actionsById[actionId], computedStates[actionId].state));
        this.store.reset(computedStates[currentStateIndex].state);
      }
    } else if (action.type === "ACTION" /* ReduxDevtoolsActionType.Action */) {
      const actionPayload = JSON.parse(action.payload);
      this.store.dispatch(actionPayload);
    }
  }
  connect() {
    if (!this.globalDevtools || this._options.disabled) {
      return;
    }
    // The `connect` method adds a `message` event listener to communicate
    // with an extension through `window.postMessage` and handle message events.
    // Since we only handle two specific events, we aim to avoid unnecessary change
    // detections triggered by events that the extension sends, but we don't need to handle.
    this.devtoolsExtension = this._ngZone.runOutsideAngular(() => this.globalDevtools.connect(this._options));
    this.unsubscribe = this.devtoolsExtension.subscribe(action => {
      if (action.type === "DISPATCH" /* ReduxDevtoolsActionType.Dispatch */ || action.type === "ACTION" /* ReduxDevtoolsActionType.Action */) {
        this.dispatched(action);
      }
    });
  }
  /** @nocollapse */
  static {
    this.ɵfac = function NgxsReduxDevtoolsPlugin_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxsReduxDevtoolsPlugin)(i0.ɵɵinject(NGXS_DEVTOOLS_OPTIONS), i0.ɵɵinject(i0.Injector), i0.ɵɵinject(i0.NgZone));
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgxsReduxDevtoolsPlugin,
      factory: NgxsReduxDevtoolsPlugin.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsReduxDevtoolsPlugin, [{
    type: Injectable
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NGXS_DEVTOOLS_OPTIONS]
    }]
  }, {
    type: i0.Injector
  }, {
    type: i0.NgZone
  }], null);
})();
function devtoolsOptionsFactory(options) {
  return {
    name: 'NGXS',
    ...options
  };
}
const USER_OPTIONS = new InjectionToken('USER_OPTIONS');
class NgxsReduxDevtoolsPluginModule {
  static forRoot(options) {
    return {
      ngModule: NgxsReduxDevtoolsPluginModule,
      providers: [{
        provide: NGXS_PLUGINS,
        useClass: NgxsReduxDevtoolsPlugin,
        multi: true
      }, {
        provide: USER_OPTIONS,
        useValue: options
      }, {
        provide: NGXS_DEVTOOLS_OPTIONS,
        useFactory: devtoolsOptionsFactory,
        deps: [USER_OPTIONS]
      }]
    };
  }
  /** @nocollapse */
  static {
    this.ɵfac = function NgxsReduxDevtoolsPluginModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxsReduxDevtoolsPluginModule)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxsReduxDevtoolsPluginModule
    });
  }
  /** @nocollapse */
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsReduxDevtoolsPluginModule, [{
    type: NgModule
  }], null, null);
})();
function withNgxsReduxDevtoolsPlugin(options) {
  return makeEnvironmentProviders([withNgxsPlugin(NgxsReduxDevtoolsPlugin), {
    provide: USER_OPTIONS,
    useValue: options
  }, {
    provide: NGXS_DEVTOOLS_OPTIONS,
    useFactory: devtoolsOptionsFactory,
    deps: [USER_OPTIONS]
  }]);
}

/**
 * The public api for consumers of @ngxs/devtools-plugin
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NGXS_DEVTOOLS_OPTIONS, NgxsReduxDevtoolsPlugin, NgxsReduxDevtoolsPluginModule, withNgxsReduxDevtoolsPlugin };
